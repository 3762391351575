import { ReportTypes } from './api.model';
import { APICore } from './apiCore';

const api = new APICore();

const baseUrl = '/reports';

function getReport(payload: { reportType: string; params: Object }) {


    if (payload.reportType == ReportTypes.MARGIN_REPORT) {
        return api.get(`${baseUrl}/margin-reports`, payload.params);
    }
    if (payload.reportType == ReportTypes.SALES_PIPELINE) {
        return api.get(`${baseUrl}/sales-pipeline`, payload.params);
    }

}

function updateSalesPipeline(payload: { reportType: string; params: Object }){
    return api.update(`${baseUrl}/sales-pipeline`, payload.params);
}

export { getReport, updateSalesPipeline }
