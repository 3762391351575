import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { ToolsApiResponseError, ToolsApiResponseSuccess } from './actions';
import { ToolsActionTypes } from './constants';
import { addRenewalsApi, deleteRenewalsApi, getDashBoardDataApi, getRenewalsApi, updateRenewalsApi } from 'helpers/api/tools';

function* getRenewalsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getRenewalsApi, { params });
        yield put(ToolsApiResponseSuccess(ToolsActionTypes.GET_RENEWALS, response.data));
    } catch (error: any) {
        yield put(ToolsApiResponseError(ToolsActionTypes.GET_RENEWALS, error));
    }
}
function* getRenewalByIdSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getRenewalsApi, { params });
        yield put(ToolsApiResponseSuccess(ToolsActionTypes.GET_RENEWAL_BY_ID, response.data));
    } catch (error: any) {
        yield put(ToolsApiResponseError(ToolsActionTypes.GET_RENEWAL_BY_ID, error));
    }
}

function* addRenewalsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(addRenewalsApi, { params });
        yield put(ToolsApiResponseSuccess(ToolsActionTypes.ADD_RENEWALS, response.data));
    } catch (error: any) {
        yield put(ToolsApiResponseError(ToolsActionTypes.ADD_RENEWALS, error));
    }
}
function* updateRenewalsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(updateRenewalsApi, { params });
        yield put(ToolsApiResponseSuccess(ToolsActionTypes.UPDATE_RENEWALS, response.data));
    } catch (error: any) {
        yield put(ToolsApiResponseError(ToolsActionTypes.UPDATE_RENEWALS, error));
    }
}
function* deleteRenewalsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(deleteRenewalsApi, { params });
        yield put(ToolsApiResponseSuccess(ToolsActionTypes.DELETE_RENEWALS, response.data));
    } catch (error: any) {
        yield put(ToolsApiResponseError(ToolsActionTypes.DELETE_RENEWALS, error));
    }
}
function* getDashBoardSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getDashBoardDataApi, { params });
        yield put(ToolsApiResponseSuccess(ToolsActionTypes.GET_DASHBOARD_DATA, response.data));
    } catch (error: any) {
        yield put(ToolsApiResponseError(ToolsActionTypes.GET_DASHBOARD_DATA, error));
    }
}
export function* watchGetRenewals() {
    yield takeEvery(ToolsActionTypes.GET_RENEWALS, getRenewalsSaga);
}
export function* watchGetRenewalsById() {
    yield takeEvery(ToolsActionTypes.GET_RENEWAL_BY_ID, getRenewalByIdSaga);
}
export function* watchAddRenewals() {
    yield takeEvery(ToolsActionTypes.ADD_RENEWALS, addRenewalsSaga);
}
export function* watchUpdateRenewals() {
    yield takeEvery(ToolsActionTypes.UPDATE_RENEWALS, updateRenewalsSaga);
}
export function* watchDeleteRenewals() {
    yield takeEvery(ToolsActionTypes.DELETE_RENEWALS, deleteRenewalsSaga);
}
export function* watchDashBoardDataRenewals() {
    yield takeEvery(ToolsActionTypes.GET_DASHBOARD_DATA, getDashBoardSaga);
}
function* toolsSaga() {
    yield all([
        fork(watchGetRenewals),
        fork(watchGetRenewalsById),
        fork(watchAddRenewals),
        fork(watchUpdateRenewals),
        fork(watchDeleteRenewals),
        fork(watchDashBoardDataRenewals),
    ]);
}
export default toolsSaga;