import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Report from './reports/reducers';
import Sales from './sales/reducers';
import Targets from './targets/quarterlytargets/reducers';
import Tools from './Tools/Renewals/reducers';


export default combineReducers({
    Auth,
    Layout,
    Report,
    Sales,
    Targets,
    Tools,
});
