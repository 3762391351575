import { APICore } from "./apiCore";


const api = new APICore();

const baseUrl = '';

export function getRenewalsApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/renewals`, payload.params);
}
export function addRenewalsApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/renewals`, payload.params);
}
export function updateRenewalsApi(payload: { params: Object }) {
    return api.update(`${baseUrl}/renewals`, payload.params);
}
export function deleteRenewalsApi(payload: { params: Object }) {
    return api.delete(`${baseUrl}/renewals`, payload.params);
}
export function getDashBoardDataApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/dashboard`, payload.params);
}