import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore } from '../../helpers/api/apiCore';
import {getReport as getReportApi } from 'helpers';
import { apiResponseError, apiResponseSuccess, getSalesPipelineData } from './actions';
import { ReportsActionTypes } from './constants';
import { updateSalesPipeline } from '../../helpers/api/reports';

const api = new APICore();


function* getReport({ payload: { reportType, params}}: any): SagaIterator {
    try {
        const response = yield call(getReportApi, {reportType, params});
        const marginReport = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(apiResponseSuccess(ReportsActionTypes.GET_REPORT_DATA, marginReport));
    } catch (error: any) {
        yield put(apiResponseError(ReportsActionTypes.GET_REPORT_DATA, error));

    }
}

function* getSalesPipelineReport({ payload: { reportType, params}}: any): SagaIterator {
    try {
        const response = yield call(getReportApi, {reportType, params});
        const salesPipeline = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(apiResponseSuccess(ReportsActionTypes.GET_SALES_PIPELINE, salesPipeline));
    } catch (error: any) {
        yield put(apiResponseError(ReportsActionTypes.GET_SALES_PIPELINE, error));

    }
}

function* updateSalesPipelineSage({ payload: { reportType, params}}: any): SagaIterator {
    try {
        const response = yield call(updateSalesPipeline, {reportType, params});
        const salesPipeline = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(apiResponseSuccess(ReportsActionTypes.UPDATE_SALES_PIPELINE, salesPipeline));
    } catch (error: any) {
        yield put(apiResponseError(ReportsActionTypes.UPDATE_SALES_PIPELINE, error));

    }
}
export function* watchGetReport() {
    yield takeEvery(ReportsActionTypes.GET_REPORT_DATA, getReport);
}
export function* watchGetSalesPipeline() {
    yield takeEvery(ReportsActionTypes.GET_SALES_PIPELINE, getSalesPipelineReport);
}

export function* watchUpdateSalesPipelineSage() {
    yield takeEvery(ReportsActionTypes.UPDATE_SALES_PIPELINE, updateSalesPipelineSage);
}


function* reportSaga() {
    yield all([fork(watchGetReport), fork(watchGetSalesPipeline), fork(watchUpdateSalesPipelineSage)]);
}

export default reportSaga;