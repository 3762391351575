import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { TargetsActionTypes } from './constants';
import { addQuarterlyTargetsApi, deleteQuarterlyTargetsApi, getQuarterlyTargetsApi, updateQuarterlyTargetsApi } from 'helpers/api/targets';
import { targetsApiResponseError, targetsApiResponseSuccess } from './action';
/* Functions */
function* getQuarterlyTargetsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getQuarterlyTargetsApi, { params });
        yield put(targetsApiResponseSuccess(TargetsActionTypes.GET_QUARTERLY_TARGETS, response.data));
    } catch (error: any) {
        yield put(targetsApiResponseError(TargetsActionTypes.GET_QUARTERLY_TARGETS, error));
    }
}
function* addQuarterlyTargetsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(addQuarterlyTargetsApi, { params });
        yield put(targetsApiResponseSuccess(TargetsActionTypes.ADD_QUARTERLY_TARGETS, response.data));
    } catch (error: any) {
        yield put(targetsApiResponseError(TargetsActionTypes.ADD_QUARTERLY_TARGETS, error));
    }
}
function* updateQuarterlyTargetsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(updateQuarterlyTargetsApi, { params });
        yield put(targetsApiResponseSuccess(TargetsActionTypes.UPDATE_QUARTERLY_TARGETS, response.data));
    } catch (error: any) {
        yield put(targetsApiResponseError(TargetsActionTypes.UPDATE_QUARTERLY_TARGETS, error));
    }
}
function* deleteQuarterlyTargetsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(deleteQuarterlyTargetsApi, { params });
        yield put(targetsApiResponseSuccess(TargetsActionTypes.DELETE_QUARTERLY_TARGETS, response.data));
    } catch (error: any) {
        yield put(targetsApiResponseError(TargetsActionTypes.DELETE_QUARTERLY_TARGETS, error));
    }
}

/* Watch */
export function* watchGetQuarterlyTargets() {
    yield takeEvery(TargetsActionTypes.GET_QUARTERLY_TARGETS, getQuarterlyTargetsSaga);
}
export function* watchAddQuarterlyTargets() {
    yield takeEvery(TargetsActionTypes.ADD_QUARTERLY_TARGETS, addQuarterlyTargetsSaga);
}
export function* watchUpdateQuarterlyTargets() {
    yield takeEvery(TargetsActionTypes.UPDATE_QUARTERLY_TARGETS, updateQuarterlyTargetsSaga);
}
export function* watchDeleteQuarterlyTargets() {
    yield takeEvery(TargetsActionTypes.DELETE_QUARTERLY_TARGETS, deleteQuarterlyTargetsSaga);
}

function* targetsSaga() {
    yield all([
        fork(watchGetQuarterlyTargets),
        fork(watchAddQuarterlyTargets),
        fork(watchUpdateQuarterlyTargets),
        fork(watchDeleteQuarterlyTargets),
    ]);
}
export default targetsSaga;
