import { useNavigate } from "react-router-dom";
import { IProjectCategory } from "../../pages/categories/modal/category";
import { IProjectClient } from "../../pages/clients/modal/clients";
import { IProject } from "../../pages/projects/modals/project";
import { IUsers } from "../modals/users";
import { getProjectByIDAction, SalesActionType } from "./actions";
import { SalesActionTypes } from "./constants";
const INIT_PROJECT = {
    "id": 0,
    "name": "",
    "clientid": 0,
    "ownerid": 0,
    "userid": null,
    "projectcategoryid": "",
    "totalamount": "15.99",
    "frequency": "one off",
    "clientname": "Baird",
    "categoryname": "New Business",
    "owner": {
        "id": 37,
        "name": "Luke Pope"
    },
    "client": {
        "id": 36,
        "name": "Baird"
    },
    "category": {
        "id": "18",
        "name": "New Business"
    },
    "project_frequencies": [
        {
            "id": 0,
            "projectid": 2236,
            "year": "2022",
            "month": "9",
            "cff": 1,
            "invoice": 1,
            "amount": "15.99",
            "action": "e"
        }
    ]
}

const INIT_QUOTE = {
    "id": 0,
    "client": "",
    "projectname": "",
    "totalcost": 0,
    "totalrrp": 0,
    "profit": 0,
    "dealvalue": 0,
    "data": {
        "printers": [{
            "id": null,
            "printer": "",
            "addon": "",
            "type": "",
            "desc": "",
            "installation": "",
            "rrp": "0",
            "nnn": "0",
            "productcode": "",
            "quantity": 1,
            "totalrrp": 0,
            "totalcost": 0,
            "subRows": []

        }],
        "additionalCosts": [{
            "id": null,
            "addionalCostType": '',
            "quantity": 1,
            "cost": 0,
            "totalcost": 0,
            "globalcost": 0
        }],
        "additionalProfitRecords": [{
            "id": null,
            "additionalProfitType": '',
            "quantity": 1,
            "cost": 0,
            "totalcost": 0,
        }]
    },
    "createddate": "",
    "updateddate": ""
}
const INIT_DIGITAL_QUOTE = {
    "id": 0,
    "client": "",
    "projectname": "",
    "totalhours": 0,
    "profit": 0,
    "subtotal": 0,
    "wiggle": 0,
    "status": 0,
    "data": [{ "id": 0, "name": "", "desc": "", "type": "", "hours": "0", "total": 0.00 }],
    "createddate": "",
    "updateddate": ""
}

export const INIT_RATE_CARD = {
    "id": 0,
    "name": "",
    "cost": "",
    "description": "",
    "frequency": "",
}
const INIT_RATE_CARDS: any[] = [
]
const INIT_STATE = {
    projects: [],
    loading: false,
    projectCreated: false,
    client: {} as IProjectClient,
    clients: [] as IProjectClient[],
    status: '',
    printers: [],
    quote: INIT_QUOTE,
    digitalquote: INIT_DIGITAL_QUOTE,
    error: "",
    ratecards: INIT_RATE_CARDS,
    ratecard: INIT_RATE_CARD
};
type State = {
    projects?: any,
    loading?: boolean;
    projectCreated: boolean;
    client: IProjectClient;
    clients: IProjectClient[]
    status: string,
    printers: any
   // quote: any
    error: any
};



const Sales = (state: State = INIT_STATE, action: SalesActionType) => {

    switch (action.type) {
        case SalesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SalesActionTypes.GET_PROJECTS: {
                    return {
                        ...state,
                        projects: action.payload.data,
                        projectCreated: false,
                        loading: false,
                        status: '@LOADED'
                    }
                };
                case SalesActionTypes.GET_PROJECTBYID: {
                    return {
                        ...state,
                        project: action.payload.data,
                        loading: false,
                        status: '@LOADED'
                    }
                };
                case SalesActionTypes.GET_CLIENT: {
                    return {
                        ...state,
                        clients: action.payload.data,
                        loading: false,
                        status: '@LOADED'
                    }
                };
                case SalesActionTypes.UPDATE_CLIENT: {
                    return {
                        ...state,
                        client: action.payload.data,
                        loading: false,
                        status: '@UPDATED'
                    }
                };
                case SalesActionTypes.UPDATE_CLIENT: {
                    return {
                        ...state,
                        clients: action.payload.data,
                        loading: false,
                        status: '@LOADED'
                    }
                };
                case SalesActionTypes.CREATE_CLIENT: {
                    return {
                        ...state,
                        client: action.payload.data,
                        loading: false,
                        status: '@CREATED'

                    }
                };
                case SalesActionTypes.DELETE_CLIENT: {
                    return {
                        ...state,
                        clients: action.payload.data,
                        loading: false,
                        status: '@LOADED'
                    }
                };
                case SalesActionTypes.GET_CATEGORY: {
                    return {
                        ...state,
                        categories: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_USERS: {
                    return {
                        ...state,
                        users: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.UPDATE_PROJECT: {
                    return {
                        ...state,
                        project: action.payload.data,
                        projectCreated: false,
                        loading: false,
                        status: '@UPDATED'
                    }
                };
                case SalesActionTypes.CREATE_PROJECT: {

                    return {
                        ...state,
                        projectCreated: true,
                        project: action.payload.data,
                        loading: false,
                        status: '@CREATED'
                    }
                };
                case SalesActionTypes.DELETE_PROJECT: {

                    return {
                        ...state,
                        projects: action.payload.data,
                        loading: false,
                        status: '@LOADED'
                    }
                };
                case SalesActionTypes.GET_TECH_PRODUCTS: {

                    return {
                        ...state,
                        printers: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.ADD_TECH_QUOTE: {

                    return {
                        ...state,
                        quotes: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.UPDATE_TECH_QUOTE: {

                    return {
                        ...state,
                        quote: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_TECH_QUOTES: {

                    return {
                        ...state,
                        quotes: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_TECH_QUOTE_BY_ID: {
                    return {
                        ...state,
                        quote: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.DELETE_TECH_QUOTE_BY_ID: {
                    return {
                        ...state,
                        quotes: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.DUPLICATE_TECH_QUOTE: {
                    return {
                        ...state,
                        quotes: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.ADD_DIGITAL_QUOTE: {

                    return {
                        ...state,
                        loading: false,
                    }
                };
                case SalesActionTypes.UPDATE_DIGITAL_QUOTE: {

                    return {
                        ...state,
                        digitalquote: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_DIGITAL_QUOTES: {

                    return {
                        ...state,
                        digitalquotes: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID: {
                    return {
                        ...state,
                        digitalquote: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID: {
                    return {
                        ...state,
                        digitalquotes: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.DUPLICATE_DIGITAL_QUOTE: {
                    return {
                        ...state,
                        digitalquotes: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_RATE_CARDS: {
                    return {
                        ...state,
                        ratecards: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_RATE_CARD_BY_ID: {
                    return {
                        ...state,
                        ratecard: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.ADD_RATE_CARDS: {
                    return {
                        ...state,
                        ratecards: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.UPDATE_RATE_CARDS: {
                    return {
                        ...state,
                        ratecards: action.payload.data,
                        loading: false,
                    }
                };
                case SalesActionTypes.DELETE_RATE_CARDS: {
                    return {
                        ...state,
                        ratecards: action.payload.data,
                        loading: false,
                    }
                };
                default:
                    return { ...state, status: '' };
            };
        case SalesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SalesActionTypes.GET_PROJECTS: {
                    return {
                        ...state,
                        projects: action.payload.error,
                        loading: false,
                        status: '@ERROR'
                    }
                };
                case SalesActionTypes.GET_PROJECTBYID: {
                    return {
                        ...state,
                        project: action.payload.error,
                        loading: false,
                        status: '@ERROR'

                    }
                };
                case SalesActionTypes.GET_CLIENT: {
                    return {
                        ...state,
                        clients: action.payload.error,
                        loading: false,
                        status: '@ERROR'
                    }

                };
                case SalesActionTypes.UPDATE_CLIENT: {
                    return {
                        ...state,
                        client: action.payload.error,
                        loading: false,
                        status: '@ERROR'

                    }
                };
                case SalesActionTypes.CREATE_CLIENT: {
                    return {
                        ...state,
                        client: action.payload.error,
                        loading: false,
                        status: '@ERROR'
                    }
                };
                case SalesActionTypes.DELETE_CLIENT: {
                    return {
                        ...state,
                        client: action.payload.error,
                        loading: false,
                        status: '@ERROR'
                    }
                };
                case SalesActionTypes.GET_CATEGORY: {
                    return {
                        ...state,
                        categories: action.payload.error,
                        loading: false,
                        status: '@ERROR'
                    }
                };
                case SalesActionTypes.GET_USERS: {
                    return {
                        ...state,
                        users: action.payload.error,
                        loading: false,
                        status: '@ERROR'
                    }
                };
                case SalesActionTypes.UPDATE_PROJECT: {
                    return {
                        ...state,
                        project: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.CREATE_PROJECT: {
                    return {
                        ...state,
                        project: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.DELETE_PROJECT: {

                    return {
                        ...state,
                        project: action.payload.error,
                        loading: false,
                    }
                }; case SalesActionTypes.GET_TECH_PRODUCTS: {

                    return {
                        ...state,
                        printers: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.ADD_TECH_QUOTE: {

                    return {
                        ...state,
                        quote: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.UPDATE_TECH_QUOTE: {

                    return {
                        ...state,
                        quote: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_TECH_QUOTES: {

                    return {
                        ...state,
                        quotes: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_TECH_QUOTE_BY_ID: {

                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.DELETE_TECH_QUOTE_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.DUPLICATE_TECH_QUOTE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.ADD_DIGITAL_QUOTE: {

                    return {
                        ...state,
                        digitalquote: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.UPDATE_DIGITAL_QUOTE: {

                    return {
                        ...state,
                        digitalquote: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_DIGITAL_QUOTES: {

                    return {
                        ...state,
                        digitalquotes: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.DUPLICATE_DIGITAL_QUOTE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_RATE_CARDS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.GET_RATE_CARD_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.ADD_RATE_CARDS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.UPDATE_RATE_CARDS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case SalesActionTypes.DELETE_RATE_CARDS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                default:
                    return { ...state, status: '' };
            };
        case SalesActionTypes.GET_PROJECTS:
            return { ...state, status: '', loading: true , project:INIT_PROJECT };
        case SalesActionTypes.GET_PROJECTBYID:
            return { ...state, status: '', loading: true, };
        case SalesActionTypes.GET_CLIENT:
            return { ...state, status: '', loading: true};
        case SalesActionTypes.UPDATE_CLIENT:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.CREATE_CLIENT:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.DELETE_CLIENT:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.GET_CATEGORY:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.GET_USERS:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.UPDATE_PROJECT:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.CREATE_PROJECT:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.DELETE_PROJECT:
            return { ...state, status: '', loading: true };
        case SalesActionTypes.GET_TECH_PRODUCTS:
            return { ...state, loading: true };
        case SalesActionTypes.ADD_TECH_QUOTE:
            return { ...state, loading: true };
        case SalesActionTypes.UPDATE_TECH_QUOTE:
            return { ...state, loading: true };
        case SalesActionTypes.GET_TECH_QUOTES:
            return { ...state, loading: true, quote: INIT_QUOTE };
        case SalesActionTypes.GET_TECH_QUOTE_BY_ID:
            return { ...state, loading: true };
        case SalesActionTypes.DELETE_TECH_QUOTE_BY_ID:
            return { ...state, loading: true };
        case SalesActionTypes.DUPLICATE_TECH_QUOTE:
            return { ...state, loading: true };
        case SalesActionTypes.ADD_DIGITAL_QUOTE:
            return { ...state, loading: true };
        case SalesActionTypes.UPDATE_DIGITAL_QUOTE:
            return { ...state, loading: true };
        case SalesActionTypes.GET_DIGITAL_QUOTES:
            return { ...state, loading: true, digitalquote: INIT_DIGITAL_QUOTE };
        case SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID:
            return { ...state, loading: true };
        case SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID:
            return { ...state, loading: true };
        case SalesActionTypes.DUPLICATE_DIGITAL_QUOTE:
            return { ...state, loading: true };
        case SalesActionTypes.GET_RATE_CARDS:
            return { ...state, loading: true };
        case SalesActionTypes.ADD_RATE_CARDS:
            return { ...state, loading: true };
        case SalesActionTypes.UPDATE_RATE_CARDS:
            return { ...state, loading: true };
        case SalesActionTypes.DELETE_RATE_CARDS:
            return { ...state, loading: true };
        // case SalesActionTypes.SET_TECH_QUOTE_DEFAULT:
        //     return { ...state, loading: true, quote: INIT_QUOTE };
        default:
            return state;
    }

}
export default Sales