import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = '';

export function getTechProductsApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/products`, payload.params);
}
export function addTechQuoteApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/quote`, payload.params);
}
export function updateTechQuoteApi(payload: { params: Object }) {
    return api.update(`${baseUrl}/quote`, payload.params);
}
export function getTechQuotesApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/quote`, payload.params ? payload.params : '');
}
export function deleteTechQuotesApi(payload: { params: Object }) {
    return api.delete(`${baseUrl}/quote`, payload.params ? payload.params : '');
}
export function duplicateTechQuotesApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/quote-duplicate`, payload.params ? payload.params : '');
}
export function addDigitalQuoteApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/digital-quote`, payload.params);
}
export function updateDigitalQuoteApi(payload: { params: Object }) {
    return api.update(`${baseUrl}/digital-quote`, payload.params);
}
export function getDigitalQuotesApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/digital-quote`, payload.params ? payload.params : '');
}
export function deleteDigitalQuotesApi(payload: { params: Object }) {
    return api.delete(`${baseUrl}/digital-quote`, payload.params ? payload.params : '');
}
export function duplicateDigitalQuotesApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/digital-quote-duplicate`, payload.params ? payload.params : '');
}
export function getRateCardsApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/ratecard`, payload.params ? payload.params : '');
}
export function addRateCardsApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/ratecard`, payload.params ? payload.params : '');
}
export function updateRateCardsApi(payload: { params: Object }) {
    return api.update(`${baseUrl}/ratecard`, payload.params ? payload.params : '');
}
export function deleteRateCardsApi(payload: { params: Object }) {
    return api.delete(`${baseUrl}/ratecard`, payload.params ? payload.params : '');
}
