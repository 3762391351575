import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import reportSaga from './reports/saga';
import salesSaga from './sales/saga';
import targetsSaga from './targets/quarterlytargets/saga';
import toolsSaga from './Tools/Renewals/saga';

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(), reportSaga(), salesSaga(), targetsSaga(), toolsSaga()]);
}
