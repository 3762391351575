export enum SalesActionTypes {
    API_RESPONSE_SUCCESS = '@@sales/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@sales/API_RESPONSE_ERROR',
    GET_PROJECTS = '@@sales/GET_PROJECTS',
    GET_PROJECTBYID = '@@sales/GET_PROJECTSBYID',

    GET_CLIENT = '@@sales/GET_CLIENTS',
    DELETE_CLIENT = '@@sales/DELETE_CLIENTS',
    UPDATE_CLIENT = '@@sales/UPDATE_CLIENTS',
    CREATE_CLIENT = '@@sales/CREATE_CLIENTS',

    GET_CATEGORY = '@@sales/GET_CATEGORY',
    GET_USERS = '@@sales/GET_USERS',
    UPDATE_PROJECT = '@@sales/UPDATE_PROJECT',
    CREATE_PROJECT = '@@sales/CREATE_PROJECT',
    DELETE_PROJECT = '@@sales/DELETE_PROJECT',

    GET_TECH_PRODUCTS = '@@sales/GET_TECH_PRODUCTS',
    ADD_TECH_QUOTE = '@@sales/ADD_TECH_QUOTE',
    UPDATE_TECH_QUOTE = '@@sales/UPDATE_TECH_QUOTE',
    GET_TECH_QUOTES = '@@sales/GET_TECH_QUOTES',
    GET_TECH_QUOTE_BY_ID = '@@sales/GET_TECH_QUOTE_BY_ID',
    DELETE_TECH_QUOTE_BY_ID = '@@sales/DELETE_TECH_QUOTE_BY_ID',
    DUPLICATE_TECH_QUOTE = '@@sales/DUPLICATE_TECH_QUOTE',
    ADD_DIGITAL_QUOTE = '@@sales/ADD_DIGITAL_QUOTE',
    UPDATE_DIGITAL_QUOTE = '@@sales/UPDATE_DIGITAL_QUOTE',
    GET_DIGITAL_QUOTES = '@@sales/GET_DIGITAL_QUOTES',
    GET_DIGITAL_QUOTE_BY_ID = '@@sales/GET_DIGITAL_QUOTE_BY_ID',
    DELETE_DIGITAL_QUOTE_BY_ID = '@@sales/DELETE_DIGITAL_QUOTE_BY_ID',
    DUPLICATE_DIGITAL_QUOTE = '@@sales/DUPLICATE_DIGITAL_QUOTE',
    GET_RATE_CARDS = '@@sales/GET_RATE_CARDS',
    GET_RATE_CARD_BY_ID = '@@sales/GET_RATE_CARD_BY_ID',
    ADD_RATE_CARDS = '@@sales/ADD_RATE_CARDS',
    UPDATE_RATE_CARDS = '@@sales/UPDATE_RATE_CARDS',
    DELETE_RATE_CARDS = '@@sales/DELETE_RATE_CARDS',
    // SET_TECH_QUOTE_DEFAULT = '@@sales/SET_TECH_QUOTE_DEFAULT'
}
