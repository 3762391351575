import { ReportTypes } from '../../helpers';
import { ReportsActionTypes } from './constants';

export type ReportActionType = {
    type:
        | ReportsActionTypes.API_RESPONSE_SUCCESS
        | ReportsActionTypes.API_RESPONSE_ERROR
        | ReportsActionTypes.GET_REPORT_DATA
        | ReportsActionTypes.GET_SALES_PIPELINE
        | ReportsActionTypes.UPDATE_SALES_PIPELINE
    payload: {} | string;
};


// common success
export const apiResponseSuccess = (actionType: string, data: any | {}): ReportActionType => ({
    type: ReportsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const apiResponseError = (actionType: string, error: string): ReportActionType => ({
    type: ReportsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getReportData = (reportType: string, params: Object): ReportActionType => ({
        type: ReportsActionTypes.GET_REPORT_DATA,
        payload: { reportType, params },
});

export const getSalesPipelineData = (reportType: string, params: Object): ReportActionType => ({
    type: ReportsActionTypes.GET_SALES_PIPELINE,
    payload: { reportType, params },
});

export const updateSalesPipelineData = (reportType: string, params: Object): ReportActionType => ({
    type: ReportsActionTypes.UPDATE_SALES_PIPELINE,
    payload: { reportType, params },
});
