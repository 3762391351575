import { TargetsActionType } from "redux/actions"
import { ToolsActionType } from "./actions"
import { ToolsActionTypes } from "./constants"
const INIT_RENEWAL_STATE = {
    client: "",
    description: "",
    expiryDate: new Date(),
    startDate: new Date(),
    id: 0,
    name: "",
    orderUrl: "",
    owner: "",
    remindIn: "0"
}
const INIT_MONTH = [
    {
        "month": "03",
        "amount": 0
    },
    {
        "month": "04",
        "amount": 0
    },
    {
        "month": "05",
        "amount": 0
    },
    {
        "month": "06",
        "amount": 0
    },
    {
        "month": "07",
        "amount": 0
    },
    {
        "month": "08",
        "amount": 0
    },
    {
        "month": "09",
        "amount": 0
    },
    {
        "month": "10",
        "amount": 0
    },
    {
        "month": "11",
        "amount": 0
    },
    {
        "month": "12",
        "amount": 0
    },
    {
        "month": "01",
        "amount": 0
    },
    {
        "month": "02",
        "amount": 0
    }
]
const INIT_STATE = {
    loading: false,
    renewals: [],
    renewal: INIT_RENEWAL_STATE,
    dashboard: {
        "monthData": INIT_MONTH,
        "techActual": INIT_MONTH,
        "digitalActual": INIT_MONTH,
        "fiscalYear": {
            "startYear": 20,
            "endYear": 20
        },
        "salesAmount": {
            "clientService": 0,
            "media": 0,
            "hosting": 0,
            "techSale": 0,
            "techService": 0
        }
    }
}
type State = {
    loading?: boolean,
    renewals?: any
}
const Tools = (state: State = INIT_STATE, action: ToolsActionType) => {
    switch (action.type) {
        case ToolsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ToolsActionTypes.GET_RENEWALS: {
                    return {
                        ...state,
                        renewals: action.payload.data,
                    };
                };
                case ToolsActionTypes.ADD_RENEWALS: {
                    return {
                        ...state,
                        renewals: action.payload.data,
                    };
                };
                case ToolsActionTypes.GET_RENEWAL_BY_ID: {
                    return {
                        ...state,
                        renewal: action.payload.data,
                    };
                };
                case ToolsActionTypes.UPDATE_RENEWALS: {
                    return {
                        ...state,
                        renewals: action.payload.data,
                    };
                };
                case ToolsActionTypes.DELETE_RENEWALS: {
                    return {
                        ...state,
                        renewals: action.payload.data,
                    };
                };
                case ToolsActionTypes.GET_DASHBOARD_DATA: {
                    return {
                        ...state,
                        dashboard: action.payload.data,
                    };
                };
                default:
                    return { ...state, };

            };
        case ToolsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ToolsActionTypes.GET_RENEWALS: {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                };
                case ToolsActionTypes.GET_RENEWAL_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                };
                case ToolsActionTypes.ADD_RENEWALS: {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                };
                case ToolsActionTypes.UPDATE_RENEWALS: {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                };
                case ToolsActionTypes.DELETE_RENEWALS: {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                };
                case ToolsActionTypes.GET_DASHBOARD_DATA: {
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                };
                default:
                    return { ...state, };

            };

        case ToolsActionTypes.GET_RENEWALS:
            {
                return { ...state, loading: true, };
            };
        case ToolsActionTypes.GET_RENEWAL_BY_ID:
            {
                return { ...state, loading: true, };
            };
        case ToolsActionTypes.ADD_RENEWALS:
            {
                return { ...state, loading: true, };
            };
        case ToolsActionTypes.UPDATE_RENEWALS:
            {
                return { ...state, loading: true, };
            };
        case ToolsActionTypes.DELETE_RENEWALS:
            {
                return { ...state, loading: true, };
            };
        case ToolsActionTypes.GET_DASHBOARD_DATA:
            {
                return { ...state, loading: true, };
            };
        default:
            return { ...state, };
    };
};
export default Tools;
