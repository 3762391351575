import { IProjectClient } from "../../pages/clients/modal/clients";
import { SalesActionTypes } from "./constants";


export type SalesActionType = {
    type:
    | SalesActionTypes.API_RESPONSE_SUCCESS
    | SalesActionTypes.API_RESPONSE_ERROR
    | SalesActionTypes.GET_PROJECTS
    | SalesActionTypes.GET_PROJECTBYID
    | SalesActionTypes.GET_CLIENT
    | SalesActionTypes.UPDATE_CLIENT
    | SalesActionTypes.CREATE_CLIENT
    | SalesActionTypes.DELETE_CLIENT
    | SalesActionTypes.GET_CATEGORY
    | SalesActionTypes.GET_USERS
    | SalesActionTypes.UPDATE_PROJECT
    | SalesActionTypes.CREATE_PROJECT
    | SalesActionTypes.DELETE_PROJECT
    | SalesActionTypes.GET_TECH_PRODUCTS
    | SalesActionTypes.ADD_TECH_QUOTE
    | SalesActionTypes.UPDATE_TECH_QUOTE
    | SalesActionTypes.GET_TECH_QUOTES
    | SalesActionTypes.GET_TECH_QUOTE_BY_ID
    | SalesActionTypes.DELETE_TECH_QUOTE_BY_ID
    | SalesActionTypes.DUPLICATE_TECH_QUOTE
    | SalesActionTypes.ADD_DIGITAL_QUOTE
    | SalesActionTypes.UPDATE_DIGITAL_QUOTE
    | SalesActionTypes.GET_DIGITAL_QUOTES
    | SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID
    | SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID
    | SalesActionTypes.DUPLICATE_DIGITAL_QUOTE
    | SalesActionTypes.GET_RATE_CARDS
    | SalesActionTypes.GET_RATE_CARD_BY_ID
    | SalesActionTypes.ADD_RATE_CARDS
    | SalesActionTypes.UPDATE_RATE_CARDS
    | SalesActionTypes.DELETE_RATE_CARDS
    // | SalesActionTypes.SET_TECH_QUOTE_DEFAULT

    payload: {
        actionType?:string
        data?: any;
        error?: string;
        params?: Object
    } ;
};


// common success
export const salesApiResponseSuccess = (actionType: string, data: any | {}): SalesActionType => ({
    type: SalesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const salesApiResponseError = (actionType: string, error: string): SalesActionType => ({
    type: SalesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getProjectsAction = (): SalesActionType => ({
    type: SalesActionTypes.GET_PROJECTS,
    payload: {},
});


export const getProjectByIDAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.GET_PROJECTBYID,
    payload: {params},
});

export const deleteProjectByIDAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.DELETE_PROJECT,
    payload: {params},
});

export const getClientAction = (params?: Object): SalesActionType => ({
    type: SalesActionTypes.GET_CLIENT,
    payload: {params},
});

export const updateClientAction = (params: IProjectClient): SalesActionType => ({
    type: SalesActionTypes.UPDATE_CLIENT,
    payload: {params},
});

export const createClientAction = (params: IProjectClient): SalesActionType => ({
    type: SalesActionTypes.CREATE_CLIENT,
    payload: {params},
});

export const deleteClientAction = (params: any): SalesActionType => ({
    type: SalesActionTypes.DELETE_CLIENT,
    payload: {params},
});


export const getCategoryAction = (params?: Object): SalesActionType => ({
    type: SalesActionTypes.GET_CATEGORY,
    payload: {params},
});
export const getUsersAction = (params?: Object): SalesActionType => ({
    type: SalesActionTypes.GET_USERS,
    payload: {params},
});

export const updateProjectAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.UPDATE_PROJECT,
    payload: {params},
});
export const deleteProjectAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.DELETE_PROJECT,
    payload: { params },
});

export const createProjectAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.CREATE_PROJECT,
    payload: {params},
});

export const getTechProductsAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.GET_TECH_PRODUCTS,
    payload: { params },
});
export const addTechQuoteAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.ADD_TECH_QUOTE,
    payload: { params },
});
export const duplicateTechQuoteAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.DUPLICATE_TECH_QUOTE,
    payload: { params },
});
export const updateTechQuoteAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.UPDATE_TECH_QUOTE,
    payload: { params },
});
export const getTechQuotesAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.GET_TECH_QUOTES,
    payload: { params },
});

export const getTechQuoteByIDAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.GET_TECH_QUOTE_BY_ID,
    payload: { params },
});
export const deleteTechQuoteByIDAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.DELETE_TECH_QUOTE_BY_ID,
    payload: { params },
});
export const addDigitalQuoteAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.ADD_DIGITAL_QUOTE,
    payload: { params },
});
export const updateDigitalQuoteAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.UPDATE_DIGITAL_QUOTE,
    payload: { params },
});
export const getDigitalQuotesAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.GET_DIGITAL_QUOTES,
    payload: { params },
});

export const getDigitalQuoteByIDAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID,
    payload: { params },
});
export const deleteDigitalQuoteByIDAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID,
    payload: { params },
});
export const duplicateDigitalQuoteAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.DUPLICATE_DIGITAL_QUOTE,
    payload: { params },
});
export const getRateCardsAction = (params?: Object): SalesActionType => ({
    type: SalesActionTypes.GET_RATE_CARDS,
    payload: { params },
});
export const getRateCardsByIdAction = (params?: Object): SalesActionType => ({
    type: SalesActionTypes.GET_RATE_CARD_BY_ID,
    payload: { params },
});
export const addRateCardsAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.ADD_RATE_CARDS,
    payload: { params },
});
export const updateRateCardsAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.UPDATE_RATE_CARDS,
    payload: { params },
});
export const deleteRateCardsAction = (params: Object): SalesActionType => ({
    type: SalesActionTypes.DELETE_RATE_CARDS,
    payload: { params },
});
// export const setTechQuoteDefault = (params?: Object): SalesActionType => ({
//     type: SalesActionTypes.GET_TECH_QUOTE_BY_ID,
//     payload: { params },
// });