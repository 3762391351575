export enum ToolsActionTypes {
    API_RESPONSE_SUCCESS = '@@tools/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@tools/API_RESPONSE_ERROR',

    GET_RENEWALS = '@@tools/GET_RENEWALS',
    GET_RENEWAL_BY_ID = '@@tools/GET_RENEWAL_BY_ID',
    ADD_RENEWALS = '@@tools/ADD_RENEWALS',
    UPDATE_RENEWALS = '@@tools/UPDATE_RENEWALS',
    DELETE_RENEWALS = '@@tools/DELETE_RENEWALS',
    GET_DASHBOARD_DATA = '@@tools/GET_DASHBOARD_DATA',

}