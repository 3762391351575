import { APICore } from "./apiCore";


const api = new APICore();

const baseUrl = '';

export function getQuarterlyTargetsApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/targets/quarterly`, payload.params);
}
export function addQuarterlyTargetsApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/targets/quarterly`, payload.params);
}
export function updateQuarterlyTargetsApi(payload: { params: Object }) {
    return api.update(`${baseUrl}/targets/quarterly`, payload.params);
}
export function deleteQuarterlyTargetsApi(payload: { params: Object }) {
    return api.delete(`${baseUrl}/targets/quarterly`, payload.params);
}