
import { filterSalesPipeline } from "../../pages/reports/hooks/UseSalesPipeline";
import { ReportsActionTypes } from "./constants";


const INIT_STATE = {
    marginReport: [],
    loading: false,
    salesPipeline: {}
};
type State = {
    marginReport?: any,
    loading?: boolean;
    salesPipeline: any
};

export type ReportActionType = {
    type:
    | ReportsActionTypes.API_RESPONSE_SUCCESS
    | ReportsActionTypes.API_RESPONSE_ERROR
    | ReportsActionTypes.GET_REPORT_DATA
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
};


const Report = (state: State = INIT_STATE, action: ReportActionType) => {
    switch (action.type) {
        case ReportsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ReportsActionTypes.GET_REPORT_DATA: {
                    return {
                        ...state,
                        marginReport: action.payload.data,
                        loading: false,
                    }
                };
                case ReportsActionTypes.GET_SALES_PIPELINE: {
                    return {
                        ...state,
                        salesPipeline: action.payload.data,
                        loading: false,
                    }
                };
                case ReportsActionTypes.UPDATE_SALES_PIPELINE: {
                    return {
                        ...state,
                        salesPipeline: action.payload.data,
                        loading: false,
                    }
                };
                default:
                    return { ...state };
            };
        case ReportsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ReportsActionTypes.GET_REPORT_DATA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    }
                };
                case ReportsActionTypes.GET_SALES_PIPELINE: {
                    return {
                        ...state,
                        salesPipeline: action.payload.error,
                        loading: false,
                    }
                };
                case ReportsActionTypes.UPDATE_SALES_PIPELINE: {
                    return {
                        ...state,
                        salesPipeline: action.payload.error,
                        loading: false,
                    }
                };
                default:
                    return { ...state };
            };
        case ReportsActionTypes.GET_REPORT_DATA:
            return { ...state, loading: true };


        default:
            return state;
    }

}
export default Report