import { TargetsActionTypes } from "./constants"

export type TargetsActionType = {
    type:
    | TargetsActionTypes.API_RESPONSE_SUCCESS
    | TargetsActionTypes.API_RESPONSE_ERROR

    | TargetsActionTypes.GET_QUARTERLY_TARGETS
    | TargetsActionTypes.ADD_QUARTERLY_TARGETS
    | TargetsActionTypes.UPDATE_QUARTERLY_TARGETS
    | TargetsActionTypes.DELETE_QUARTERLY_TARGETS
    payload: {
        actionType?: string
        data?: any;
        error?: string;
        params?: Object
    };

}
export const targetsApiResponseSuccess = (actionType: string, data: any | {}): TargetsActionType => ({
    type: TargetsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
export const targetsApiResponseError = (actionType: string, error: string): TargetsActionType => ({
    type: TargetsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
export const getQuarterlyTargetsAction = (params?: Object): TargetsActionType => ({
    type: TargetsActionTypes.GET_QUARTERLY_TARGETS,
    payload: { params },
})
export const addQuarterlyTargetsAction = (params?: Object): TargetsActionType => ({
    type: TargetsActionTypes.ADD_QUARTERLY_TARGETS,
    payload: { params },
})
export const updateQuarterlyTargetsAction = (params?: Object): TargetsActionType => ({
    type: TargetsActionTypes.UPDATE_QUARTERLY_TARGETS,
    payload: { params },
})
export const deleteQuarterlyTargetsAction = (params?: Object): TargetsActionType => ({
    type: TargetsActionTypes.DELETE_QUARTERLY_TARGETS,
    payload: { params },
})