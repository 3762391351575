import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore } from "../../helpers/api/apiCore";
import { getReportApi } from '../../helpers';
import { getTechQuoteByIDAction, salesApiResponseError, salesApiResponseSuccess } from './actions';
import { SalesActionTypes } from './constants';
import { createClientApi, createProjectApi, deleteClientApi, deleteProjectApi, getCatogoryApi, getClientApi, getProjectByIDApi, getUsersApi, updateClientApi, updateProjectApi } from '../../helpers/api/sales';
import { useNavigate } from 'react-router-dom';
import { addDigitalQuoteApi, addRateCardsApi, addTechQuoteApi, deleteDigitalQuotesApi, deleteRateCardsApi, deleteTechQuotesApi, duplicateDigitalQuotesApi, duplicateTechQuotesApi, getDigitalQuotesApi, getRateCardsApi, getTechProductsApi, getTechQuotesApi, updateDigitalQuoteApi, updateRateCardsApi, updateTechQuoteApi } from 'helpers/api/quotes';
import { AuthActionTypes } from 'redux/auth/constants';
// import { create } from 'sortablejs';



const api = new APICore();

function* getReportSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getReportApi, { params });
        const projects = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_PROJECTS, projects));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_PROJECTS, error));

    }
}


function* getProjectByIdSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getProjectByIDApi, { params });
        const project = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_PROJECTBYID, project));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_PROJECTBYID, error));

    }
}

function* getClientSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getClientApi, { params });
        const project = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_CLIENT, project));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_CLIENT, error));

    }
}

function* updateClientSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(updateClientApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.UPDATE_CLIENT, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPDATE_CLIENT, error));

    }
}


function* createClientSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(createClientApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.CREATE_CLIENT, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.CREATE_CLIENT, error.response.data));

    }
}


function* deleteClientSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(deleteClientApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.DELETE_CLIENT, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DELETE_CLIENT, error.response.data));

    }
}

function* getCategorySaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getCatogoryApi, { params });
        const project = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_CATEGORY, project));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_CATEGORY, error));

    }
}
function* getUsersSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getUsersApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_USERS, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_USERS, error));

    }
}

function* updateProjectSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(updateProjectApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.UPDATE_PROJECT, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPDATE_PROJECT, error));

    }
}

function* getTechProductsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getTechProductsApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_TECH_PRODUCTS, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_TECH_PRODUCTS, error));

    }
}

function* createProjectSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(createProjectApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.CREATE_PROJECT, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.CREATE_PROJECT, error));

    }
}
function* deleteProjectSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(deleteProjectApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.DELETE_PROJECT, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DELETE_PROJECT, error));
    }
}

function* addTechQuoteSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(addTechQuoteApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_TECH_QUOTE, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_TECH_QUOTE, error));

    }
}
function* updateTechQuoteSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(updateTechQuoteApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.UPDATE_TECH_QUOTE, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPDATE_TECH_QUOTE, error));

    }
}
function* getTechQuotesSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getTechQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_TECH_QUOTES, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_TECH_QUOTES, error));

    }
}
function* getTechQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getTechQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_TECH_QUOTE_BY_ID, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_TECH_QUOTE_BY_ID, error));

    }
}
function* deleteTechQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(deleteTechQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.DELETE_TECH_QUOTE_BY_ID, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DELETE_TECH_QUOTE_BY_ID, error));

    }
}
function* duplicateTechQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(duplicateTechQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.DUPLICATE_TECH_QUOTE, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DUPLICATE_TECH_QUOTE, error));

    }
}
function* addDigitalQuoteSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(addDigitalQuoteApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_DIGITAL_QUOTE, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_DIGITAL_QUOTE, error));

    }
}
function* updateDigitalQuoteSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(updateDigitalQuoteApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.UPDATE_DIGITAL_QUOTE, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPDATE_DIGITAL_QUOTE, error));

    }
}
function* getDigitalQuotesSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getDigitalQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_DIGITAL_QUOTES, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_DIGITAL_QUOTES, error));

    }
}
function* getDigitalQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getDigitalQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID, error));

    }
}
function* deleteDigitalQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(deleteDigitalQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID, error));
    }
}
function* duplicateDigitalQuoteSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(duplicateDigitalQuotesApi, { params });
        const data = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(salesApiResponseSuccess(SalesActionTypes.DUPLICATE_DIGITAL_QUOTE, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DUPLICATE_DIGITAL_QUOTE, error));
    }
}

function* getRateCardsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getRateCardsApi, { params });
        const data = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_RATE_CARDS, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_RATE_CARDS, error));
    }
}
function* getRateCardsByIdSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(getRateCardsApi, { params });
        const data = response.data[0];
        yield put(salesApiResponseSuccess(SalesActionTypes.GET_RATE_CARD_BY_ID, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.GET_RATE_CARD_BY_ID, error));
    }
}
function* addRateCardsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(addRateCardsApi, { params });
        const data = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_RATE_CARDS, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_RATE_CARDS, error));
    }
}
function* updateRateCardsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(updateRateCardsApi, { params });
        const data = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.UPDATE_RATE_CARDS, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPDATE_RATE_CARDS, error));
    }
}
function* deleteRateCardsSaga({ payload: { params } }: any): SagaIterator {
    try {
        const response = yield call(deleteRateCardsApi, { params });
        const data = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.DELETE_RATE_CARDS, data));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DELETE_RATE_CARDS, error));
    }
}



export function* watchGetProjectsSaga() {
    yield takeEvery(SalesActionTypes.GET_PROJECTS, getReportSaga);
}

export function* watchGetProjectByIdSaga() {
    yield takeEvery(SalesActionTypes.GET_PROJECTBYID, getProjectByIdSaga);
}
export function* watchgetClientSaga() {
    yield takeEvery(SalesActionTypes.GET_CLIENT, getClientSaga);
}

export function* watchupdateClientSaga() {
    yield takeEvery(SalesActionTypes.UPDATE_CLIENT, updateClientSaga);
}
export function* watchcreateClientSaga() {
    yield takeEvery(SalesActionTypes.CREATE_CLIENT, createClientSaga);
}

export function* watchdeleteClientSaga() {
    yield takeEvery(SalesActionTypes.DELETE_CLIENT, deleteClientSaga);
}
export function* watchgetCategorySaga() {
    yield takeEvery(SalesActionTypes.GET_CATEGORY, getCategorySaga);
}
export function* watchGetUsersSaga() {
    yield takeEvery(SalesActionTypes.GET_USERS, getUsersSaga);
}

export function* watchUpdateProjectSaga() {
    yield takeEvery(SalesActionTypes.UPDATE_PROJECT, updateProjectSaga);
}

export function* watchCreateProjectSaga() {
    yield takeEvery(SalesActionTypes.CREATE_PROJECT, createProjectSaga);
}
export function* watchDeleteProjectSaga() {
    yield takeEvery(SalesActionTypes.DELETE_PROJECT, deleteProjectSaga);
}
export function* watchgetTechProductsApiSaga() {
    yield takeEvery(SalesActionTypes.GET_TECH_PRODUCTS, getTechProductsSaga);
}
export function* watchAddTechQuoteSaga() {
    yield takeEvery(SalesActionTypes.ADD_TECH_QUOTE, addTechQuoteSaga);
}
export function* watchUpdateTechQuoteSaga() {
    yield takeEvery(SalesActionTypes.UPDATE_TECH_QUOTE, updateTechQuoteSaga);
}
export function* watchgetTechQuotesSaga() {
    yield takeEvery(SalesActionTypes.GET_TECH_QUOTES, getTechQuotesSaga);
}
export function* watchgetTechQuoteByIDSaga() {
    yield takeEvery(SalesActionTypes.GET_TECH_QUOTE_BY_ID, getTechQuoteByIDSaga);
}
export function* watchdeleteTechQuoteByIDSaga() {
    yield takeEvery(SalesActionTypes.DELETE_TECH_QUOTE_BY_ID, deleteTechQuoteByIDSaga);
}
export function* watchduplicateTechQuoteByIDSaga() {
    yield takeEvery(SalesActionTypes.DUPLICATE_TECH_QUOTE, duplicateTechQuoteByIDSaga);
}
export function* watchAddDigitalQuoteSaga() {
    yield takeEvery(SalesActionTypes.ADD_DIGITAL_QUOTE, addDigitalQuoteSaga);
}
export function* watchUpdateDigitalQuoteSaga() {
    yield takeEvery(SalesActionTypes.UPDATE_DIGITAL_QUOTE, updateDigitalQuoteSaga);
}
export function* watchgetDigitalQuotesSaga() {
    yield takeEvery(SalesActionTypes.GET_DIGITAL_QUOTES, getDigitalQuotesSaga);
}
export function* watchgetDigitalQuoteByIDSaga() {
    yield takeEvery(SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID, getDigitalQuoteByIDSaga);
}
export function* watchdeleteDigitalQuoteByIDSaga() {
    yield takeEvery(SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID, deleteDigitalQuoteByIDSaga);
}
export function* watchduplicateDigitalQuoteSaga() {
    yield takeEvery(SalesActionTypes.DUPLICATE_DIGITAL_QUOTE, duplicateDigitalQuoteSaga);
}
export function* watchgetRateCardsSaga() {
    yield takeEvery(SalesActionTypes.GET_RATE_CARDS, getRateCardsSaga);
}
export function* watchgetRateCardsByIdSaga() {
    yield takeEvery(SalesActionTypes.GET_RATE_CARD_BY_ID, getRateCardsByIdSaga);
}
export function* watchaddRateCardsSaga() {
    yield takeEvery(SalesActionTypes.ADD_RATE_CARDS, addRateCardsSaga);
}
export function* watchupdateRateCardsSaga() {
    yield takeEvery(SalesActionTypes.UPDATE_RATE_CARDS, updateRateCardsSaga);
}
export function* watchdeleteRateCardsSaga() {
    yield takeEvery(SalesActionTypes.DELETE_RATE_CARDS, deleteRateCardsSaga);
}



function* salesSaga() {
    yield all([fork(watchGetProjectsSaga),
        fork(watchGetProjectByIdSaga),
        fork(watchgetClientSaga),
        fork(watchgetCategorySaga),
        fork(watchGetUsersSaga),

        fork(watchUpdateProjectSaga),
        fork(watchCreateProjectSaga),
        fork(watchDeleteProjectSaga),

        fork(watchupdateClientSaga),
        fork(watchcreateClientSaga),
        fork(watchgetTechProductsApiSaga),
        fork(watchdeleteTechQuoteByIDSaga),
        fork(watchduplicateTechQuoteByIDSaga),
        fork(watchAddTechQuoteSaga),
        fork(watchgetTechQuotesSaga),
        fork(watchUpdateTechQuoteSaga),
        fork(watchgetTechQuoteByIDSaga),
        fork(watchAddDigitalQuoteSaga),
        fork(watchgetDigitalQuotesSaga),
        fork(watchgetDigitalQuoteByIDSaga),
        fork(watchdeleteDigitalQuoteByIDSaga),
        fork(watchduplicateDigitalQuoteSaga),
        fork(watchUpdateDigitalQuoteSaga),
        fork(watchdeleteClientSaga),
        fork(watchgetRateCardsSaga),
        fork(watchgetRateCardsByIdSaga),
        fork(watchaddRateCardsSaga),
        fork(watchupdateRateCardsSaga),
        fork(watchdeleteRateCardsSaga),
    ]);
}


export default salesSaga;