import { ReportTypes } from "./api.model";
import { APICore } from "./apiCore";


const api = new APICore();

const baseUrl = '';

export function getReportApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/projects`, payload.params);
}


export function getProjectByIDApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/projects`, payload.params);
}


export function getClientApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/clients`, payload.params);
}
export function updateClientApi(payload: { params: Object }) {
    return api.update(`${baseUrl}/clients`, payload.params);
}
export function createClientApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/clients`, payload.params)

}
export function deleteClientApi(payload: { params: Object }) {
    return api.delete(`${baseUrl}/clients`, payload.params)

}


export function getCatogoryApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/categories`, payload.params);
}

export function getUsersApi(payload: { params: Object }) {
    return api.get(`${baseUrl}/user`, payload.params);
}

export function updateProjectApi(payload: { params: Object }) {
    return api.update(`${baseUrl}/projects`, payload.params);
}

export function createProjectApi(payload: { params: Object }) {
    return api.create(`${baseUrl}/projects`, payload.params);
}
export function deleteProjectApi(payload: { params: Object }) {
    return api.delete(`${baseUrl}/projects`, payload.params);
}