import { ToolsActionTypes } from "./constants"

export type ToolsActionType = {
    type:
    | ToolsActionTypes.API_RESPONSE_SUCCESS
    | ToolsActionTypes.API_RESPONSE_ERROR

    | ToolsActionTypes.GET_RENEWALS
    | ToolsActionTypes.GET_RENEWAL_BY_ID
    | ToolsActionTypes.ADD_RENEWALS
    | ToolsActionTypes.UPDATE_RENEWALS
    | ToolsActionTypes.DELETE_RENEWALS

    | ToolsActionTypes.GET_DASHBOARD_DATA
    payload: {
        actionType?: string
        data?: any;
        error?: string;
        params?: Object
    };

}
export const ToolsApiResponseSuccess = (actionType: string, data: any | {}): ToolsActionType => ({
    type: ToolsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
export const ToolsApiResponseError = (actionType: string, error: string): ToolsActionType => ({
    type: ToolsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
export const getRenewalsAction = (params?: Object): ToolsActionType => ({
    type: ToolsActionTypes.GET_RENEWALS,
    payload: { params },
})
export const getRenewalByIdAction = (params?: Object): ToolsActionType => ({
    type: ToolsActionTypes.GET_RENEWAL_BY_ID,
    payload: { params },
})
export const addRenewalsAction = (params?: Object): ToolsActionType => ({
    type: ToolsActionTypes.ADD_RENEWALS,
    payload: { params },
})
export const updateRenewalsAction = (params?: Object): ToolsActionType => ({
    type: ToolsActionTypes.UPDATE_RENEWALS,
    payload: { params },
})
export const deleteRenewalsAction = (params?: Object): ToolsActionType => ({
    type: ToolsActionTypes.DELETE_RENEWALS,
    payload: { params },
})

export const getDashBoardDataAction = (params?: Object): ToolsActionType => ({
    type: ToolsActionTypes.GET_DASHBOARD_DATA,
    payload: { params },
});